<template>
    <div v-show="isNavDisplayable && !forceHide" :class="isNavAddOpen ? 'open' : null">
        <div class="menu-container">
            <div class="add" @click="onNavAddClick">
                <router-link
                    v-for="(menu, idx) in menus"
                    :key="`menu-mobile-${idx}`"
                    :to="menu.to"
                    :class="`add_${idx}`"
                >
                    <font-awesome-icon @click="$store.commit('nav/close')" :icon="menu.icon" />
                </router-link>
            </div>
            <div class="menu container-fluid px-2 pt-3">
                <div class="row align-items-center no-gutters">
                    <div class="col-auto">
                        <a id="btn_menu">
                            <font-awesome-icon
                                v-if="isNavOpen"
                                :icon="['fal', 'times-square']"
                                @click="$store.commit('nav/close')"
                            />
                            <font-awesome-icon
                                v-else
                                :icon="['fal', 'bars']"
                                @click="$store.commit('nav/open')"
                            />
                        </a>
                    </div>
                    <div
						class="col-auto mr-auto"
						@click="openSearch"
					>
                        <a><font-awesome-icon :icon="['fal', 'search']" /></a>
                    </div>
                    <div class="col-auto">
                        <a>
                            <font-awesome-icon
                                v-if="isNavNotificationsOpen"
                                :icon="['fal', 'times-square']"
                                @click="$store.commit('nav/closeNotifications')"
                            />
                            <NotificationsBell
                                v-else
                                :icon="['fal', 'bell']"
                                @click="$store.commit('nav/openNotifications')"
                            />
                        </a>
                    </div>
                    <div class="col-auto">
                        <a>
                            <font-awesome-icon
                                v-if="isNavFooterOpen"
                                :icon="['fal', 'times-square']"
                                @click="$store.commit('nav/closeFooter')"
                            />
                            <font-awesome-icon
                                v-else
                                :icon="['fal', 'user-cog']"
                                @click="$store.commit('nav/openFooter')"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from 'EventBus'
import Menus from '@/assets/js/menus/mobile'
import Navigation from '@/mixins/Navigation'

export default {
    mixins: [Navigation],
    components: {
        NotificationsBell: () => import('@/components/Header/NotificationsBell.vue')
    },
    data() {
        return {
			menus: [],
			tablesSelected: [],
            events_tab: {
				'User::AccessRightsReloaded': this.reloadMenus,
				'NavMobile::ShowNav': (identifier) => {
					if(!this.tablesSelected.includes(identifier)) {
						this.tablesSelected.push(identifier)
					}
				},
				'NavMobile::HideNav': (identifier) => {
					const idx = this.tablesSelected.indexOf(identifier)
					if (idx !== -1) {
						this.tablesSelected.splice(idx, 1)
					}
				},
            }
        }
    },
    computed: {
		forceHide() {
			return this.tablesSelected.length > 0
		},
		isNavDisplayable() {
			const parent_rights = this.$route.matched.find(record => record.meta.canDisplayNavMobile !== undefined)
			return parent_rights !== undefined ? parent_rights.meta.canDisplayNavMobile : true
		},
        isNavOpen() {
            return this.$store.state.nav.isOpen
        },
        isNavFooterOpen() {
            return this.$store.state.nav.isFooterOpen
        },
        isNavAddOpen() {
            return this.$store.state.nav.isAddOpen
        },
        isNavNotificationsOpen() {
            return this.$store.state.nav.isNotificationsOpen
        }
    },
    watch: {
        '$route'() {
            this.$store.commit('nav/closeAdd');
            this.$store.commit('nav/closeNotifications');
        }
    },
    created() {
        this.reloadMenus()
    },
    methods: {
        reloadMenus() {
			this.menus = Menus.user(this.$route.name)
        },
        onNavAddClick() {
            this.isNavAddOpen ? this.$store.commit('nav/closeAdd') : this.$store.commit('nav/openAdd')
        },
		openSearch() {
			this.$store.commit('nav/open')
			EventBus.$emit('SearchInput::focus')
		}
    }
}
</script>
