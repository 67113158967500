import Menus from '@/assets/js/menus'
import Mvp from './mvp'

export default {
    all() {
        return []
        .concat(Mvp)
    },
    user(actual_route) {
        const menus = this.all()

		Menus.removeUnauthorizedMenus(menus)
		
		menus.forEach(menu => {
			if(menu.needFrom) {
				menu.to.params = {
					from: actual_route
				}
			}
		})

        return menus
    }
}
