export default [
    {
        icon: ['fal', 'horse'],
        to: { name: 'horseAjout' },
		needFrom: false,
        accessRights: 'MVP'
    },
    {
        icon: ['fal', 'tasks'],
		to: { name: 'acteAjout' },
		needFrom: true,
        accessRights: 'MVP'
    },
    {
        icon: ['fal', 'route'],
        to: { name: 'mouvementAjout' },
		needFrom: true,
        accessRights: 'MVP'
    },
    {
        icon: ['fal', 'file-medical-alt'],
        to: { name: 'ordonnanceAjout' },
		needFrom: true,
        accessRights: 'MVP'
    }
]
